@import 'src/design-system/color-palette/color-palette';
@import 'src/styles/variables';

.header {
  margin-top: constant(safe-area-inset-top);
  margin-top: env(safe-area-inset-top);
  height: 56px;
  .closeButton {
    fill: $base_white;
    position: fixed;
    top: calc(4px + constant(safe-area-inset-top));
    top: calc(4px + env(safe-area-inset-top));
    left: 4px;
    z-index: $headerZIndex;
  }
  .backButton {
    fill: #272e40;
    position: fixed;
    top: calc(4px + constant(safe-area-inset-top));
    top: calc(4px + env(safe-area-inset-top));
    left: 4px;
    z-index: $headerZIndex;
  }
}
