@import 'src/design-system/color-palette/color-palette';

.remainedTimeWrapper {
  color: $base_white;
  font-weight: bold;
  .remainedTime {
    min-width: 120px;
    display: inline-block;
  }
}

.eventEndDimmer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(black, 0.2);
}
