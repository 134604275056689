.dimmer {
  z-index: 99;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  background-color: rgba(#272e40, 0.7);
  position: fixed;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  background-color: white;
  min-width: 100px;
  min-height: 100px;
}
