@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css);
@import './styles/nomalize.css';
@import 'src/design-system/color-palette/color-palette';

* {
  font-family: 'Spoqa Han Sans Neo', 'sans-serif';
  -webkit-tap-highlight-color: transparent;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
}

html {
  scroll-behavior: smooth;
}

body {

}

a {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
  &:focus {
    text-decoration: none;
  }
}
