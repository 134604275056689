@import 'src/design-system/color-palette/color-palette';

.inputWrapper {
  margin-top: 16px;
  .phoneNumberInput {
    font-weight: bold;
  }
}

.info {
  color: $base_light;
  font-size: 14px;
  line-height: 24px;
  margin-top: 16px;
  margin-bottom: 32px;
}

.confirmButton {
  width: 100%;
  font-weight: bold;
}
