@import 'src/design-system/color-palette/color-palette';

.modal {
  width: 90vw;
  box-shadow: 0 32px 64px rgba(39, 46, 64, 0.08);
  background: $base_gray2;
}
.body {
  padding: 32px 32px 0 32px;
  .description {
    margin-top: 16px;
    word-break: keep-all;
  }
  .phoneNumberWrapper {
    margin-top: 16px;
    padding: 16px 20px;
    border-radius: 4px;
    background: $base_white;
  }
}

.checkbox {
  transform: translateX(-26px);
}

.footer {
  padding: 0 16px 24px 16px;
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
  .confirmButton {
    margin-left: 16px;
    padding-left: 29px;
    padding-right: 29px;
    font-weight: bold;
    .checkIcon {
      fill: $base_white;
      margin-right: 8px;
    }
  }
}
