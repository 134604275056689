@import '../../color-palette/color-palette';

.wrapper {
  display: flex;
  align-items: center;
  border-radius: 4px;
  background-color: $base_white;
  border: 1px solid $base_gray3;
  box-shadow: 0 2px 4px $base_8;
  padding: 0 16px;
  box-sizing: border-box;
  .input {
    padding: 14px 0;
    width: 100%;
    border: none;
    outline: none;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    background-color: inherit;
    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera*/
    }

    &::placeholder {
      color: $base_gray4;
      font-weight: normal;
    }
  }

  .small {
    font-size: 14px;
    line-height: 24px;
  }

  .big {
    font-size: 16px;
    line-height: 28px;
  }
  @media (hover: hover) {
    &:hover {
      background-color: $base_gray1;
      border: 1px solid $base_gray6;
      box-shadow: 0 2px 4px $base_8 !important;
    }
  }

  &:focus-within {
    background-color: $base_white;
    border: 1px solid $brandcolor_primary;
    box-shadow: 0 2px 4px $brandcolor_16;
  }

  &.warning {
    background-color: $base_white !important;
    border: 1px solid $warning_primary !important;
    box-shadow: 0 2px 4px $warning_16 !important;
  }
  &.disabled {
    background-color: $base_gray1 !important;
    border: 1px solid $base_gray3 !important;
    box-shadow: none !important;
    color: $base_gray4 !important;
  }
  .deleteIcon {
    padding: 4px;
  }
}

.rightAddon {
  padding-right: 0;
}

// number 타입 위, 아래 화살표 숨기기
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
