@import 'src/design-system/color-palette/color-palette';

.cloneCard {
  position: fixed;
  background: $base_white;
  .fuel {
    width: 100%;
  }
  .backSideWrapper {
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
  }

  .particle {
    position: absolute;
    top: calc(20% + 20px);
    left: 4%;
    opacity: 0;
    transition: 0.3s ease-in-out;
    width: 90%;
    height: 33%;
  }

  .show {
    opacity: 1;
  }
}

.cloneContent {
  position: fixed;
  .liter {
    font-size: 40px;
    font-weight: bold;
    white-space: pre;
  }
  .info {
    margin-bottom: 4px;
    font-size: 16px;
    color: $base_gray6;
    white-space: pre;
  }
}
