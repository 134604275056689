@import 'src/styles/functions';
@import 'src/styles/mixin';
@import 'src/design-system/color-palette/color-palette';
@import 'src/styles/animations';

.card {
  border-radius: 4px;
  width: 100%;
  height: 100%;
  perspective: 1000px;
  transition: transform 0.8s, box-shadow 0.8s;
  transform-style: preserve-3d;
  .logo {
    width: getVw(50);
    height: getVw(16);
  }
  @include tablet {
    .logo {
      width: getMaxVw(50);
      height: getMaxVw(16);
    }
  }
}


.glow {
  background-color: transparent;
  animation: pulse ease-in-out 1s infinite;
}

.shaking {
  animation: shake 0.5s;
}

.flip {
  transform: rotateY(180deg);
}

.flipped {
  transform: rotateY(180deg);
  transition: none;
}



@keyframes pulse {
  0% {
    box-shadow: 0 0 8px 4px rgba(#869ab7, 0.5);
  }
  50% {
    box-shadow: 0 0 16px 4px rgba(#869ab7, 0.5);
  }
  100% {
    box-shadow: 0 0 8px 4px rgba(#869ab7, 0.5);
  }
}
