@import '../../design-system/color-palette/color-palette.scss';
@import 'src/styles/variables';

.dimmer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(black, 0.5);
  display: none;
}

.isOpen {
  display: block;
}

.wrapper {
  width: 100%;
  box-sizing: border-box;
  position: fixed;
  z-index: $bottomSheetDialogZIndex;
  bottom: 0;
  transition: height 0.2s linear, opacity 0.3s;
  background-color: $base_gray2;
  border-radius: 16px 16px 0 0;
  overflow: hidden;
}
.content {
  padding: 22px 24px 0 24px;
  padding-bottom: calc(16px + constant(safe-area-inset-bottom));
  padding-bottom: calc(16px + env(safe-area-inset-bottom));
  position: relative;
}
.closeIconWrap {
  position: absolute;
  top: 12px;
  right: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  .closeIcon {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}

.noTransition {
  transition: none !important;
}

.caption {
  text-align: center;
  color: $brandcolor_primary;
}

.button {
  font-weight: bold;
  margin-top: 40px;
  width: 100%;
}
