@import '../../../../design-system/color-palette/color-palette';
@import '../../../../styles/functions';

.cloneCard {
  position: fixed;
  background: $base_white;
  .gift {
    width: 100%;
  }
  .backSideWrapper {
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
  }

  .particle {
    position: absolute;
    top: 21%;
    opacity: 0;
    transition: 0.3s ease-in-out;
    width: 99%;
    height: 38%;
  }

  .show {
    opacity: 1;
  }
}

.cloneContent {
  position: fixed;
  .name {
    white-space: pre;
    font-size: 10vw;
    font-weight: bold;
  }
}
