@import '../color-palette/color-palette.scss';

.button {
  // defaults
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  transition: 0.2s all;
  -webkit-tap-highlight-color: transparent;

  .marginRight {
    display: inline;
    margin-right: 4px;
  }
  .marginLeft {
    display: inline;
    margin-left: 4px;
  }
}

.primaryBrandcolor {
  background-color: $brandcolor_primary;
  box-shadow: 0 2px 4px $brandcolor_32;
  color: $base_white;
  border: none;

  @media (hover: hover) {
    &:hover {
      background-color: $brandcolor_light;
      box-shadow: 0 2px 4px $brandcolor_32;
    }
  }

  &:active {
    background-color: $brandcolor_dark;
    box-shadow: none;
  }
}

.secondaryGray {
  background-color: $base_white;
  box-shadow: 0 2px 4px $base_8;
  color: $base_gray6;
  border: 1px solid $base_gray3;

  @media (hover: hover) {
    &:hover {
      background-color: $base_gray1;
    }
  }

  &:active {
    background-color: $base_gray2;
  }
}

.textBrandcolor {
  // text-brandcolor는 disabled 상태에도 배경색이 없게 한다.
  background-color: transparent !important;
  color: $brandcolor_primary;
  border: none;
}

.big {
  font-size: 16px;
  padding: 16px;
  line-height: 24px;
}

.tiny {
  font-size: 12px;
  line-height: 16px;
  padding: 8px;
}

.disabled {
  border: none !important;
  color: $base_gray4 !important;
  background-color: $base_gray2;
  box-shadow: none !important;
  cursor: auto;

  @media (hover: hover) {
    &:hover {
      background-color: $base_gray2;
    }
  }
}
