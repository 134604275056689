@import 'src/design-system/color-palette/color-palette';

.layout {
  background-color: $base_dark;
  min-height: 100vh;

  .noToken {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 56px);
    .info {
      color: $base_gray6;
    }
  }
}
.flipped {
  padding-top: 56px;
}

.content {
  box-sizing: border-box;
  padding: 12px 24px 87px 24px;

  .header {
    display: grid;
    gap: 16px;
    margin-bottom: 32px;
    .selectCard {
      font-size: 32px;
      line-height: 52px;
      font-weight: 700;
    }
    .remainTime {
      color: $brandcolor_light;
    }
  }
  .shareButton {
    position: fixed;
    bottom: calc(13px + constant(safe-area-inset-bottom));
    bottom: calc(13px + env(safe-area-inset-bottom));

    left: 16px;
    width: calc(100% - 32px);
    font-weight: bold;
    .kakaoIcon {
      margin-left: 8px;
    }
  }
}
