@import 'src/styles/functions';
@import 'src/styles/mixin';
@import 'src/design-system/color-palette/color-palette';

.cardList {
  position: relative;
  height: getVw(393);
  .cardWrapper {
    position: absolute;
  }
  @include tablet() {
    height: getMaxVw(393);
  }
}
