@import 'src/design-system/color-palette/color-palette';

.content {
  position: absolute;
  top: calc(64px + constant(safe-area-inset-top));
  top: calc(64px + env(safe-area-inset-top));
  left: 24px;
  .description {
    color: $base_gray6;
    margin-top: 8px;
  }
}
