@import '../../../../design-system/color-palette/color-palette';

.front,
.back {
  border-radius: 4px;
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

.front {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1b202e;
  .logo {
    width: 43px;
    height: 23px;
  }
}

.back {
  transform: rotateY(180deg);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.name {
  position: absolute;
  bottom: 14px;
}
